import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
 
function SEO({ description, lang, meta, title, tagline }) {
    const { site } = useStaticQuery(
        graphql`
       query {
         site {
           siteMetadata {
             title
             tagline
             description
             author
           }
         }
       }
     `,
    );
 
    const metaTagline = tagline || site.siteMetadata.tagline;
    const metaDescription = description || site.siteMetadata.description;
 
    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                    name: 'description',
                    content: metaDescription,
                },
                {
                    name: 'tagline',
                    content: metaTagline,
                },
                {
                    property: 'og:title',
                    content: title,
                },
                {
                    property: 'og:description',
                    content: metaDescription,
                },
                {
                    property: 'og:tagline',
                    content: metaTagline,
                },
                {
                    property: 'og:type',
                    content: 'website',
                },
                {
                    name: 'twitter:card',
                    content: 'summary',
                },
                {
                    name: 'twitter:creator',
                    content: site.siteMetadata.author,
                },
                {
                    name: 'twitter:title',
                    content: title,
                },
                {
                    name: 'twitter:description',
                    content: metaDescription,
                },
                {
                    name: 'twitter:tagline',
                    content: metaTagline,
                },
            ].concat(meta)}
        />
    );
}
 
SEO.defaultProps = {
    lang: 'en',
    meta: [],
    description: '',
    tagline: '',
};
 
SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    tagline: PropTypes.string,
};
 
export default SEO;
 