import React from 'react';

import { Link } from 'gatsby';
import { HeroLogo } from '../components/heroLogo';
import Card from '../components/card';
import Layout from '../components/layout';
import SEO from '../components/seo';

// button styling
// eslint-disable-next-line max-len
const styleButton = 'inline-block bg-yellow-300 my-10 py-3 px-5 text-white font-medium tracking-wider rounded-md transform transition duration-500 hover:shadow-2xl hover:-translate-y-0.5 hover:text-gray-500';

const contactFormLink = <Link to='/contactForm'><button className={`${styleButton} px-8`}>Get in Touch</button></Link>;
const botLink = <a href='https://www.messenger.com/t/218Solutions/'><button className={styleButton}>Sol on Messenger</button></a>;


const Main = props => {
    return (
        <>
            <Layout>
                <SEO title='Homepage'/>

                <div className='p-2'>
                    <div className='HeroGray relative h-screen px-3 pt-5'>
                        <div className='py-2.5 pl-3'>
                            <HeroLogo />
                        </div>

                        <div className='table w-full h-4/5'>
                            <div className='table-cell align-middle text-center'>
                                <h1 className='text-6xl font-extrabold tracking-widest text-gray-700'>A LAB FOR</h1>
                                <h1 className='text-6xl font-extrabold tracking-widest text-gray-700'>INVENTION</h1>
                                <h3 className='text-3xl font-semibold tracking-widest text-gray-700 pt-3.5'>
                                    DESIGN <span className='text-yellow-300'>&amp;</span> DEVELOPMENT
                                </h3>
                            </div>
                        </div>
                    </div>

                   
                    <div className='grid text-center justify-items-center pt-20 mt-4 pb-40'>
                        <h3 className='text-2xl font-semibold text-gray-600 pb-3 tracking-wide'>
                            WHAT WE <span className='pl-1 text-yellow-300'>DO</span>
                        </h3>
                        <div className='bg-gradient-to-r from-yellow-200 to-yellow-300 py-px px-4 w-16 rounded'></div>
                       
                        <div className='mt-2 pt-6 pb-2 noto-serif text-gray-500 tracking-normal md:tracking-wide'>
                            <p className='inline text-xl'>2</p>
                            <p className='inline text-xl'>1</p>
                            <h3 className='inline text-2xl'>
                            8Sol designs and develops user interfaces. 
                            </h3>
                        </div>
                      
                        <h3 className='noto-serif text-2xl text-gray-500 tracking-normal md:tracking-wide'>
                        Creating the <span className='text-gray-700 tracking-wide'>&quot;Wow!&quot;</span> that your users experience.
                        </h3>
                        
                    </div>

                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-24 w-full px-9'>    
                        <Card
                            title='Talk to us about your project'
                            extlink={contactFormLink}
                        >
                            <p>
                                Are you looking to bring your ideas to life?
                                <br />
                                Get in touch and let&apos;s talk.
                                We are here to help.
                                <br /> 
                                It costs nothing to have a chat.
                            </p>
                        </Card>
        
                        <Card
                            title='Making technology work for you'
                            extlink={botLink}
                        >
                            <p>
                                We use emerging technologies to create
                                <br />
                                innovative, cool and exciting user interactions.
                                <br />Like our chatbot, Sol. Check it out.
                            </p>
                        </Card>
                    </div>

                    <div className='HeroGray w-screen -mx-2 -mb-2 mt-48 pt-20 pb-40 px-10'>

                        <div className='pt-10 pb-14 noto-serif justify-items-center'>
                            <h3 className='text-2xl text-gray-500 text-center tracking-normal md:tracking-wide'>
                                We expect every interface that we design to be a 
                            </h3>
                                
                            <h3 className='text-2xl text-gray-700 pt-1 text-center tracking-wide'>great experience</h3>
                        </div>
                        
                        <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-24 w-full px-9 py-12 items-center justify-items-center'>

                            <div className='text-center'>
                                <p className='text-base font-light tracking-wider leading-relaxed text-gray-500'>
                                    Are you on a limited budget looking for affordable results? Look no further.
                                    Our purpose is to supply cost effective solutions that meet your requirements.
                                </p>
                            </div>

                            <div className='text-center'>
                                <p className='text-base font-light tracking-wider leading-relaxed text-gray-500'>
                                    We are passionate about designing effective and intuitive user interfaces. 
                                    Our mission is to build applications that help people in meaningful ways.
                                </p>
                            </div>

                        </div>

                    </div>
                </div>
            
            </Layout>
        </>
    );
};


export default Main;
