import React from 'react';
import PropTypes from 'prop-types';


const Card = ({ title, children, extlink }) => {
    return (
        <div className='rounded h-full'>
            <div className='text-center CardInner'>
                <h3 className='text-lg font-semibold tracking-wider text-gray-600 py-7'>{title}</h3>
                <div className='text-base font-light tracking-wider leading-relaxed text-gray-500'>
                    {children}
                </div>
                {extlink}
            </div>
        </div>
    );
};

Card.propTypes = {
    title: PropTypes.string,
    children: PropTypes.element,
    extlink: PropTypes.element,
};

export default Card;
