import { useStaticQuery, graphql } from 'gatsby';

export const useSiteMetadata = () => {
    const { site } = useStaticQuery(
        graphql`
            query SiteMetaData {
                site {
                    siteMetadata {
                        author
                        description
                        keywords
                        owner
                        companyNumber
                        telNumber
                        address
                        released
                        tagline
                        title
                        contactUrl
                        email
                    }
                }
            }
        `,
    );

    return site.siteMetadata;
};