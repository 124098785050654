/* eslint-disable max-len */
import React, { useState } from 'react';
import { Link } from 'gatsby';

import { useSiteMetadata } from '../hooks/siteMetadata';
import Layout from '../components/layout';
import SEO from '../components/seo';

// styles
const styleTextBody = 'text-base font-light tracking-wider leading-relaxed text-gray-500';
const styleButton = 'bg-yellow-300 py-2 px-7 text-white font-light tracking-wider rounded-md transform transition duration-700 hover:shadow-2xl hover:-translate-y-0.5 hover:text-gray-500';
const animateNavLink ='text-base font-medium tracking-wider text-yellow-300 transform transition duration-700 hover:shadow-2xl hover:-translate-y-0.5 hover:text-yellow-400';



const ContactForm = props => {
    
    const [sendername, setsendername] = useState('');
    const [senderemail, setsenderemail] = useState('');
    const [sendermessage, setsendermessage] = useState('');
    const [showsuccess, setshowsuccess] = useState(false);

    const { contactUrl, email, telNumber } = useSiteMetadata();

    const handleSenderName = (event) => (
        setsendername(event.target.value)
    );

    const handleSenderEmail = (event) => (
        setsenderemail(event.target.value)
    );

    const handleSenderMessage = (event) => (
        setsendermessage(event.target.value)
    );

    const handleSubmit = async (event) => {
        event.preventDefault();

        const endpoint = contactUrl;
        // We use JSON.stringify here so the data can be sent as a string via HTTP
        const body = JSON.stringify({
            senderName: sendername,
            senderEmail: senderemail,
            message: sendermessage,
        });
        const requestOptions = {
            method: 'POST',
            body,
        };

        const result = await fetch(endpoint, requestOptions)
            .then((response) => {
                if (!response.ok) throw new Error('Error in fetch');
                
                return response.json();
            })
            .then((response) => {
                console.log('Email sent successfully!');
                
                return response;
            })
            .catch((error) => {
                console.log(error, 'An unkown error occured.');
            });

        result && setshowsuccess(true);

    };


    return (
        <>
            <SEO title='Contact Form'/>
            <Layout>

                {!showsuccess &&
                    <div className='pt-10 pl-8 w-max'>
                        <Link to='/'>
                            <div className={`py-2 px-3 ${animateNavLink}`}>
                                Go back to Main Page
                            </div>
                        </Link>
                    </div>
                }

                <div className='px-10 sm:px-20 md:px-32 lg:px-56 xl:px-72 pt-14 pb-72'>

                    <div className='text-lg font-semibold tracking-wider text-gray-600 py-7 text-center'>
                        <h2>Contact Us</h2>
                    </div>

                    <div className='pb-7'>
                        <p className={`${styleTextBody} pb-3`}>
                            Use the form below to get in touch or email us at <a href={`mailto:${email}`} className='text-yellow-300 font-medium'>{email}</a>
                        </p>
                        <p className={`${styleTextBody} pb-6`}>If you prefer to talk, call us on {telNumber}</p>

                        <hr />
                    </div>
           
                    <div>
                        <form>
                            <div className='pb-1'>
                                <label className={`${styleTextBody} block mb-2`} htmlFor='sendername'>Name</label>
                                <input 
                                    name='sendername' 
                                    className={`${styleTextBody} block box-border border w-full max-w-3xl p-2 mb-2`}
                                    type='text' 
                                    aria-describedby='name' 
                                    value={sendername} 
                                    onChange={handleSenderName} />
                            </div>

                            <div className='pb-1'>
                                <label className={`${styleTextBody} block mb-2`} htmlFor='senderemail'>Email</label>
                                <input 
                                    name='senderemail' 
                                    className={`${styleTextBody} block box-border border w-full max-w-3xl p-2 mb-2`}
                                    type='email' 
                                    aria-describedby='email' 
                                    value={senderemail} 
                                    onChange={handleSenderEmail} />
                            </div>

                            <div className='pb-1.5'>
                                <label className={`${styleTextBody} block mb-2`} htmlFor='sendermessage'>Message</label>
                                <textarea 
                                    name='sendermessage' 
                                    className={`${styleTextBody} block box-border border w-full max-w-3xl p-2 mb-2`}
                                    rows='10'
                                    type='text' 
                                    aria-describedby='email' 
                                    value={sendermessage} 
                                    onChange={handleSenderMessage} />
                            </div>

                            {!showsuccess &&
                                <button className={styleButton} onClick={handleSubmit}>
                                    Send
                                </button>
                            }
                        </form>

                        {showsuccess &&
                            <>
                                <div className='my-5 p-3 border border-green-200 bg-green-100 text-base font-normal tracking-wider text-gray-500'>
                                    <h3>Success - Your message has been sent. Thank you!</h3>
                                </div>

                                <div className='w-max'>
                                    <Link to='/'>
                                        <div className={`py-2 px-0 ${animateNavLink}`}>
                                            Go back to Main Page
                                        </div>
                                    </Link>
                                </div>
                            </>
                        }
                    </div>

                </div>

            </Layout>
        </>
    );
};


export default ContactForm;
