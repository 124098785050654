import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export function HeroLogo() {

    return <StaticImage 
        src='../images/logo.png'
        alt='218Sol logo'
        placeholder='blurred'
        layout='constrained'

        width={120}
    />;
}