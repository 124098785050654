import React from 'react';

import { useSiteMetadata } from '../hooks/siteMetadata';
import { FooterLogo } from '../components/footerLogo';

// footer text style
const styleFooterText = 'text-gray-300 font-light tracking-wide';



const Footer = props => {

    const { released, owner, companyNumber, address } = useSiteMetadata();
    
  
    return (
        <footer className='h-44 bg-black flex w-full'>
            <div className='self-center w-full'>
                <div className='float-left px-8 pt-7'>

                    <FooterLogo />
                </div>

                <div className='float-right px-8 pt-6'>
                    <p className={`${styleFooterText} text-xs`}>{released} &copy; {owner}</p>
                    <p className={`${styleFooterText} footerTextSmall`}>No.{companyNumber} Incorporated in England and Wales</p>
                    <p className={`${styleFooterText} footerTextSmall`}>{address}</p>
                </div>
            </div>
            
        </footer>
    );
};


Footer.propTypes = {
   
};

export default Footer;
